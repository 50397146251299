import { Endpoint } from '../configs/serviceConfig';
import http from './http';

const Api = {
  /**
   * **********************************************************
   * landing
   */
  getSymbolPriceList: () => {
    return http.get(`cryptizi/api/v1/landing/getSymbolPriceList`);
  },

  contactUs: (data) => {
    return http.post(`cryptizi/api/v1/contactUs/message`, data);
  },

  /**
   * **********************************************************
   * general
   */
  getSymbolLogo: (symbol) => {
    return http.get(`cryptizi/api/v1/general/getSymbolLogo`, { params: { symbol } });
  },
  getPopup: () => {
    return http.get(`cryptizi/api/v1/general/popupSetting`, { params: { popupType: 'WEB' } });
  },
  getEMoneySetting: () => {
    return http.get(`cryptizi/api/v1/general/eMoneySetting`);
  },
  getIranianPaymentStatus: () => {
    return http.get(`cryptizi/api/v1/general/iranianPayment`);
  },

  /**
   * **********************************************************
   * user dashboard
   */
  getUserDashboardInfo: () => {
    return http.get(`cryptizi/api/v1/dashboard/getUserDashboardInfo`);
  },
  calculateDashboard: (fiat, symbol, amount = 1) => {
    return http.get(`cryptizi/api/v1/dashboard/calculation`, { params: { fiat, symbol, amount } });
  },
  getUserDashboardCandleInfo: (dashboardCandleTime = 'ONE_MONTH') => {
    return http.get(`cryptizi/api/v1/dashboard/getUserDashboardCandleInfo`, { params: { dashboardCandleTime } });
  },

  /**
   * **********************************************************
   * user
   */
  userPreRegistration: (data) => {
    return http.post('cryptizi/api/v1/user/userPreRegistration', data);
  },
  userRegistration: (data) => {
    return http.post('cryptizi/api/v1/user/userRegistration', data);
  },
  // operation = Login | Register | ChangeEmail
  resendOtp: (emailAddress, operationType) => {
    return http.get(`cryptizi/api/v1/user/resendOtp`, { params: { emailAddress, operationType } });
  },
  userLogin: (data) => {
    return http.post(`cryptizi/api/v1/user/userLogin`, data);
  },
  loginTwoFaVerify: (data) => {
    return http.post(`cryptizi/api/v1/user/loginTwoFaVerify`, data);
  },
  signOut: () => {
    return http.get(`cryptizi/api/v1/user/signOut`);
  },
  getCountryList: () => {
    return http.get(`cryptizi/api/v1/general/getCountryList`);
  },
  getCountryById: (id) => {
    return http.get(`cryptizi/api/v1/general/getCountryById`, { params: { id } });
  },
  setUserBasicAuthInfo: (data) => {
    return http.post(`cryptizi/api/v1/user/setUserBasicAuthInfo`, data);
  },
  setUserIntermediateAuthInfo: (data) => {
    return http.post(`cryptizi/api/v1/user/setUserIntermediateAuthInfo`, data);
  },
  setUserAdvanceAuthInfo: (data) => {
    return http.post(`cryptizi/api/v1/user/setUserAdvanceAuthInfo`, data);
  },
  getUserInfo: () => {
    return http.get(`cryptizi/api/v1/user/getUserInfo`);
  },
  addBankCard: (data) => {
    return http.post(`cryptizi/api/v1/user/addBankCard`, data);
  },
  getBankCard: () => {
    return http.get(`cryptizi/api/v1/user/getBankCard`);
  },
  forgetPassword: (emailAddress) => {
    return http.post(`cryptizi/api/v1/user/forgetPassword`, null, { params: { emailAddress } });
  },
  resetPassword: (data) => {
    return http.post(`cryptizi/api/v1/user/resetPassword`, data);
  },
  changePassword: (data) => {
    return http.post(`cryptizi/api/v1/user/changePassword`, data);
  },
  getDefaultUserSetting: () => {
    return http.get(`cryptizi/api/v1/user/getDefaultUserSetting`);
  },
  saveUserSetting: (data) => {
    return http.post(`cryptizi/api/v1/user/saveUserSetting`, data);
  },
  getUserProfilePicture: () => {
    return http.get(`cryptizi/api/v1/user/getUserProfilePicture`);
  },
  preTwoFaActivation: (data) => {
    return http.post(`cryptizi/api/v1/user/preTwoFaActivation`, data);
  },
  twoFaActivation: (payload, otp) => {
    return http.post(`cryptizi/api/v1/user/twoFaActivation`, payload, { params: { otp } });
  },
  preTwoFaDeActivation: (data) => {
    return http.post(`cryptizi/api/v1/user/preTwoFaDeActivation`, data);
  },
  twoFaDeActivation: (payload, otp) => {
    return http.post(`cryptizi/api/v1/user/twoFaDeActivation`, payload, { params: { otp } });
  },
  preChangeEmail: (oldEmailAddress, newEmailAddress) => {
    return http.post(`cryptizi/api/v1/user/preChangeEmail`, { oldEmailAddress, newEmailAddress });
  },
  changeEmail: (newEmailOtp, emailOtp = null) => {
    return http.post(`cryptizi/api/v1/user/changeEmail`, {
      newEmailOtp,
      emailOtp,
    });
  },
  // type: ADD | CHANGE
  preChangeOrAddMobile: (oldMobile, newMobile, type = 'CHANGE') => {
    return http.post(`cryptizi/api/v1/user/preChangeOrAddMobile`, { oldMobile, newMobile, type });
  },
  // type: ADD | CHANGE
  changeOrAddMobile: (newSmsOtp, oldSmsOtp, type = 'CHANGE') => {
    return http.post(`cryptizi/api/v1/user/changeOrAddMobile`, {
      newSmsOtp,
      oldSmsOtp,
      type,
    });
  },
  oauthLogin: (provider = 'GOOGLE', referralCode = '') => {
    return `${Endpoint}cryptizi/api/v1/userFederation/oauthLogin?provider=${provider}&referralCode=${referralCode}`;
  },
  getUserLevelSetting: (level = 'BASIC') => {
    return http.get(`cryptizi/api/v1/user/userLevelSetting`, { params: { level } });
  },

  /**
   * **********************************************************
   * wallet
   */
  getAllCurrencies: () => {
    return http.get(`cryptizi/api/v1/wallet/getAllCurrencies`);
  },
  getActiveSymbols: () => {
    return http.get(`cryptizi/api/v1/wallet/activeSymbols`);
  },

  getNetworks: () => {
    return http.get(`cryptizi/api/v1/admin/binanceTokenInfo/networks`);
  },
  symbolNetworkInfo: (symbol) => {
    return http.get(`cryptizi/api/v1/wallet/getUserAvailableAmount`, { params: { symbol } });
  },
  getSymbolNetworkInfo: (symbol) => {
    return http.get(`cryptizi/api/v1/wallet/symbolNetworkInfo`, { params: { symbol } });
  },
  getUserFavoriteWallet: () => {
    return http.get(`cryptizi/api/v1/wallet/userFavoriteWallet`);
  },
  addUserFavoriteWallet: (symbol) => {
    return http.post(`cryptizi/api/v1/wallet/userFavoriteWallet`, null, { params: { symbol } });
  },
  deleteUserFavoriteWallet: (symbol) => {
    return http.delete(`cryptizi/api/v1/wallet/userFavoriteWallet`, { params: { symbol } });
  },
  getUserWallet: (onlyNoneZero) => {
    return http.get(`cryptizi/api/v1/wallet/getUserWallet`, {
      params: { onlyNoneZero: onlyNoneZero ? 'true' : 'false' } ,
      headers:{
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Accept:'application/json',

      },
    });
  },

  getUserFutureWallet: () => {
    return http.get(`cryptizi/api/v1/wallet/futuresWallet`, {
      headers:{
        accept: 'application/json',
        'Content-Type': 'multipart/form-data',
        Accept:'application/json',
      },
    });
  },


  getUserAvailableAmount: (symbol) => {
    return http.get(`cryptizi/api/v1/wallet/getUserAvailableAmount`, { params: { symbol } });
  },
  getSymbolDepositAddress: (symbol, network) => {
    return http.get(`cryptizi/api/v1/wallet/symbolDepositAddress`, { params: { symbol, network } });
  },
  requestDeposit: (txId) => {
    return http.post(`cryptizi/api/v1/wallet/deposit`, null, { params: { txId } });
  },
  preWithdraw: (payload) => {
    return http.post(`cryptizi/api/v1/wallet/preWithdraw`, payload);
  },

  getCurrencyFeeFOrWithdraw : (symbol)=>{
    return http.get(`/cryptizi/api/v1/wallet/internalWithdrawInfo?symbol=${symbol}`, );

  },
  verifyWithdraw: (payload) => {
    return http.post(`cryptizi/api/v1/wallet/verifyWithdraw`, payload);
  },
  resendOtpWallet: () => {
    return http.post(`cryptizi/api/v1/wallet/resendOtp`);
  },
  getAddressBooks: () => {
    return http.get(`cryptizi/api/v1/wallet/addressBook`);
  },
  addAddressBook: (address, symbol, memo) => {
    return http.post(`cryptizi/api/v1/wallet/addressBook`, { address, symbol, memo });
  },
  deleteAddressBook: (id) => {
    return http.delete(`cryptizi/api/v1/wallet/addressBook`, { params: { id } });
  },

  /**
   * **********************************************************
   * deposit
   */
  requestDepositRial: (amount, cardId) => {
    return http.post(`cryptizi/api/v1/deposit/requestDepositRial`, { cardId }, { params: { amount } });
  },
  zarinpalRequestDeposit : (amount, cardId) =>{
    return http.post(`/api/v1/fiat/zarinpalRequestDeposit`, { cardId , amount })
  },


  /**
   * **********************************************************
   * trade
   */
  getAllSymbolInfo: () => {
    return http.get(`cryptizi/api/v1/trade/getAllSymbolInfo`);
  },
  getOrderBook: (symbol) => {
    return http.get(`cryptizi/api/v1/trade/getOrderBook`, { params: { symbol } });
  },
  getUserTradeInfo: ({ startDate, finishDate, symbol }) => {
    return http.get(`cryptizi/api/v1/trade/getUserTradeInfo`, { params: { startDate, finishDate, symbol } });
  },
  getMarketActivity: () => {
    return http.get(`cryptizi/api/v1/trade/marketActivity`);
  },
  deleteOrder: (orderId, symbol) => {
    return http.delete(`cryptizi/api/v1/trade/deleteOrder`, { params: { orderId, symbol } });
  },
  createOrder: (data) => {
    return http.post(`cryptizi/api/v1/trade/createOrder`, data);
  },
  getUserFavoriteSymbol: (symbol) => {
    return http.get(`cryptizi/api/v1/trade/userFavoriteSymbol`);
  },
  addUserFavoriteSymbol: (symbol) => {
    return http.post(`cryptizi/api/v1/trade/userFavoriteSymbol`, null, { params: { symbol } });
  },
  deleteUserFavoriteSymbol: (symbol) => {
    return http.delete(`cryptizi/api/v1/trade/userFavoriteSymbol`, { params: { symbol } });
  },

  /**
   * **********************************************************
   * history
   */
  getUserTradeHistory: (page = 0, size = 10, filters = { side: 'BUY', state: 'CANCELED', type: 'LIMIT' }) => {
    return http.get(`cryptizi/api/v1/report/getUserTradeHistory`, { params: { page, size, ...filters } });
  },
  getUserTransaction: (
    page = 0,
    size = 10,
    filters = {
      allTransaction: 'true',
      depositStatus: 'SUCCESS',
      fiatStatus: 'SUCCESS',
      withdrawStatus: 'Completed',
      startTime: '',
      endTime: '',
      symbol: '',
      transactionId: '',
      type: 'Deposit',
    }
  ) => {
    return http.get(`cryptizi/api/v1/report/getUserTransactionHistory`, { params: { page, size, ...filters } });
  },
  getUserOtcHistory: (page = 0, size = 10, filters = { side: 'BUY' }) => {
    return http.get(`cryptizi/api/v1/report/getUserOtcHistory`, { params: { page, size, ...filters } });
  },
  getUserConvertHistory: (page = 0, size = 10, filters = { startTime: '', endTime: '', status: 'SUCCESS' }) => {
    return http.get(`cryptizi/api/v1/report/userConvertHistory`, { params: { page, size, ...filters } });
  },
  getUserFuturesHistory: (page = 0, size = 10, filters = { side: 'LONG', status: 'CLOSED' }) => {
    return http.get(`cryptizi/api/v1/report/userFuturesHistory`, { params: { page, size, ...filters } });
  },
  getUserPaysafeHistory: (page = 0, size = 10, filters = { side: 'BUY', startTime: '', endTime: '' }) => {
    return http.get(`cryptizi/api/v1/report/userPaysafeHistory`, { params: { page, size, ...filters } });
  },
  getUserPerfectMoneyHistory: (
    page = 0,
    size = 10,
    filters = { side: 'BUY', status: 'COMPLETED', type: 'NORMAL', startTime: '', endTime: '' }
  ) => {
    return http.get(`cryptizi/api/v1/report/userPerfectMoneyHistory`, { params: { page, size, ...filters } });
  },

  /**
   * **********************************************************
   * OTC
   */
  calculateOTC: (symbol, fiat, symbolAmount = 0, fiatAmount = 0, side = 'BUY') => {
    return http.get(`cryptizi/api/v1/otc/calculation`, { params: { fiat, fiatAmount, symbol, symbolAmount, side } });
  },
  getOtcSymbolsInfo: () => {
    return http.get(`cryptizi/api/v1/otc/otcSymbolsInfo`);
  },
  requestOtcOrder: (payload) => {
    return http.post(`cryptizi/api/v1/otc/order`, payload);
  },

  /**
   * **********************************************************
   * P2P
   */
  getAllPendingP2POrders: (side = 'BUY', symbol = 'USDT') => {
    return http.get(`cryptizi/api/v1/p2p/allPendingOrders`, {
      params: { side: side === 'BUY' ? 'BUY' : 'SELL', symbol },
    });
  },
  getP2POrders: (page, size, filters = { side: 'ALL', state: 'ALL' }) => {
    return http.get(`cryptizi/api/v1/p2p/order`, { params: { page, size, ...filters } });
  },
  requestP2POrder: (payload) => {
    return http.post(`cryptizi/api/v1/p2p/order`, payload);
  },
  takeP2POrder: (id, amount) => {
    return http.post(`cryptizi/api/v1/p2p/takeOrder`, { id, amount });
  },
  deleteP2POrder: (id) => {
    return http.delete(`cryptizi/api/v1/p2p/order`, { params: { id } });
  },

  /**
   * **********************************************************
   * Perfect Money
   */
  getPerfectMoneyUnit: (side = 'buy', type = 'NORMAL', fiatType = 'USD') => {
    return http.get(`cryptizi/api/v1/perfectMoney/unitPrice`, { params: { side, type, fiatType } });
  },
  getPerfectMoneyConfig: () => {
    return http.get(`cryptizi/api/v1/perfectMoney/config`);
  },
  perfectMoneyOrder: (payload) => {
    return http.post(`cryptizi/api/v1/perfectMoney/order`, payload);
  },
  perfectMoneyPayment: (params) => {
    return http.get(`cryptizi/api/v1/perfectMoney/payment`, { params });
  },
  perfectMoneyNoPayment: (paymentId) => {
    return http.get(`cryptizi/api/v1/perfectMoney/noPayment`, { params: { PAYMENT_ID: paymentId } });
  },

  /**
   * **********************************************************
   * Paysafe
   */
  getPaysafeUnit: (side = 'buy', fiatType = 'USD') => {
    return http.get(`cryptizi/api/v1/paysafe/unitPrice`, { params: { side, fiatType } });
  },
  paysafeOrder: (payload) => {
    return http.post(`cryptizi/api/v1/paysafe/order`, payload);
  },

  /**
   * **********************************************************
   * Ticket
   */
  getTickets: (page = 0, size = 10) => {
    return http.get(`cryptizi/api/v1/ticket/allUserTicket`, { params: { page, size } });
  },
  // description / name / section: FINANCE, KYC, TECHNICAL
  addTicket: (payload) => {
    return http.post(`cryptizi/api/v1/ticket/ticket`, payload);
  },
  closeTicket: (ticketId) => {
    return http.put(`cryptizi/api/v1/ticket/closeTicket`, null, { params: { ticketId } });
  },
  getTicketMessages: (ticketId) => {
    return http.get(`cryptizi/api/v1/ticket/ticketMessage`, { params: { ticketId } });
  },
  // message, ticketId
  addTicketMessage: (payload) => {
    return http.post(`cryptizi/api/v1/ticket/ticketMessage`, payload);
  },
  setTicketScore: (ticketId, score) => {
    return http.put(`cryptizi/api/v1/ticket/ticketScore`, null, { params: { ticketId, score } });
  },
  deleteTicket: (ticketId) => {
    return http.put(`cryptizi/api/v1/ticket/deleteTicket`, null, { params: { ticketId } });
  },
  getTicketTransactions: () => {
    return http.get(`cryptizi/api/v1/ticket/transactionInfo`);
  },

  /**
   * **********************************************************
   * Convert
   */
  getConvertPairs: (symbol) => {
    return http.get(`cryptizi/api/v1/convert/pairs`, { params: { symbol } });
  },
  getConvertQuote: (toSymbol, fromSymbol, fromAmount) => {
    return http.get(`cryptizi/api/v1/convert/quote`, { params: { fromSymbol, toSymbol, fromAmount } });
  },
  convertAccept: (toSymbol, fromSymbol, fromAmount) => {
    return http.post(`cryptizi/api/v1/convert/accept`, null, { params: { fromSymbol, toSymbol, fromAmount } });
  },

  /**
   * **********************************************************
   * Referral
   */
  getReferralInfo: () => {
    return http.get(`cryptizi/api/v1/referral/info`);
  },
  setReferralInfo: (share) => {
    return http.post(`cryptizi/api/v1/referral/info`, null, { params: { share } });
  },
  getReferralReport: () => {
    return http.get(`cryptizi/api/v1/referral/report`);
  },

  /**
   * **********************************************************
   * Futures
   */
  getFuturesPositions: () => {
    return http.get(`cryptizi/api/v1/futures/positions`);
  },
  getFuturesOrders: (page = 0, size = 10) => {
    return http.get(`cryptizi/api/v1/futures/orders`, { params: { page, size } });
  },
  getAllSymbolFutures: () => {
    return http.get(`cryptizi/api/v1/futures/getAllSymbolInfo`);
  },
  // side = ALL | BUY | SELL
  // type = ALL | LIMIT | MARKET | STOP_LIMIT
  addPositionFutures: (
    payload = { amount: 0, leverage: 0, price: 0, side: 'ALL', stopPrice: 0, symbol: '', type: 'ALL' }
  ) => {
    return http.post(`cryptizi/api/v1/futures/position`, payload);
  },
  // type = ADD | REMOVE
  adjustMarginFutures: (amount, positionId, type) => {
    return http.post(`cryptizi/api/v1/futures/adjustMargin`, { amount, positionId, type });
  },
  // type = LIQUIDATION_PRICE | PNL | TARGET_PRICE
  // side = LONG | SHORT
  calculateFutures: (
    payload = { entryPrice: 0, exitPrice: 0, leverage: 0, quantity: 0, roe: 0, side: 'LONG', type: 'LIQUIDATION_PRICE' }
  ) => {
    return http.post(`cryptizi/api/v1/futures/calculate`, payload);
  },
  // type = ALL | LIMIT | MARKET | STOP_LIMIT
  // side = ALL | BUY | SELL
  closePositionFutures: (
    positionId,
    payload = { amount: 0, price: 0, side: 'ALL', stopPrice: 0, symbol: '', type: 'ALL' }
  ) => {
    return http.post(`cryptizi/api/v1/futures/closePosition`, payload, { params: { positionId } });
  },
  deleteFuturesOrder: (orderId) => {
    return http.delete(`cryptizi/api/v1/futures/order`, { params: { orderId } });
  },
  getFuturesPastPositions: (page = 0, size = 10, filters = { side: 'LONG', status: 'CLOSED' }) => {
    return http.get(`cryptizi/api/v1/futures/pastPositions`, { params: { page, size, ...filters } });
  },

  futuresChangeLeverage: ( leverage  ,  symbol ) => {
    return http.post(`cryptizi//api/v1/futures/changeLeverage`, {leverage  ,  symbol});
  },


  /**
   * **********************************************************
   * Notifications
   */
  getAllNotifications: (page, size) => {
    return http.get(`cryptizi/api/v1/notification/allNotifications`, { params: { page, size } });
  },
  getNotifications: () => {
    return http.get(`cryptizi/api/v1/notification/notification`);
  },
  seenNotification: (id) => {
    return http.put(`cryptizi/api/v1/notification/seen`, null, { params: { id } });
  },

  /**
   * **********************************************************
   * Market
   */
  getMarketCaps: (filters) => {
    return http.get(`cryptizi/api/v1/market/marketCap`, { params: filters });
  },
  getMarketCategory: (category, filters) => {
    return http.get(`cryptizi/api/v1/market/category`, { params: { category, ...filters } });
  },

  /**
   * **********************************************************
   * Fiat
   */
  exchangeAccountForDeposit: () => {
    return http.get(`/cryptizi/api/v1/fiat/exchangeAccountForDeposit`);
  },
  requestWithdrawFiat: (
    account,
    amount,
    fiatType // ALL, AUD, BRL, EUR, GBP, IRR, NGN, PLN, RON, RUB, TRY, UAH, USD, ZAR
  ) => {
    return http.post(`cryptizi/api/v1/fiat/withdraw`, { account, amount, fiatType });
  },
  receiptDepositFiat: (
    payload = { account: '', amount: 0, date: 0, depositType: '', fiatType: 'ALL', image: '', trackingCode: '' }
  ) => {
    return http.post(`cryptizi/api/v1/fiat/receiptDeposit`, payload);
  },
  getFiatHistory: (page = 0, size = 10, filters = {}) => {
    return http.get(`cryptizi/api/v1/fiat/transactions`, { params: { page, size, ...filters } });
  },

  transferToFuturesWallet: (
    amount,
  ) => {
    return http.post(`cryptizi/api/v1/wallet/transferToFuturesWallet`, { amount});
  },
  transferToSpotWallet: (
    amount,
  ) => {
    return http.post(`cryptizi/api/v1/wallet/transferToSpotWallet`, { amount});
  },
};

export default Api;
